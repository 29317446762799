/* eslint no-undef: 0 */
import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_BASE_URL

// export const saveRoomData = ({commit}, room) => {
//     console.log('Room Action');
//     console.log(room);
//     console.log(apiUrl + '/save-room')
//     return new Promise((resolve, reject) => {
//         axios.post(apiUrl + '/save-room', room).then((response) => {
//             console.log('Action SAVe Responsed');
//             commit('SET_CUSTOMER_NUMBER', room.user_id)
//             console.log('CHAT ROOM RESPONSE:')
//             console.log(response)
//             resolve(response)
//         }).catch((error) => {
//             reject(error)
//         })
//     })
// }

export const saveRoomData = ({commit}, room) => {
    axios.post(apiUrl + '/save-room', room).then((response) => {
        console.log('Action SAVe Responsed');
        commit('SET_CUSTOMER_NUMBER', room.user_id)
        console.log('CHAT ROOM RESPONSE:')
        console.log(response)
    }).catch((error) => {
        console.log(error)
    })
}



