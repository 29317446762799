<template>
  <div>
    <div class="chat-form" v-if="isChatForm">
      <section class="section" v-if="isCreator"> <!-- section f. creator -->
        <div class="row">
          <div class="col s12">
            <div class="center form-width">
              <h5>{{ $t('chat.chat_room_create') }}</h5>
              <form class="join" action="#" @submit.prevent="join">
                <div class='input-field col s12'>
                  <input class="validate" type="text" name="chat-room" id="chat-room" v-model.trim="room"/>
                  <label for="chat-room" class="grey-text text-darken-2 active">{{ $t('chat.room_id') }}</label>
                </div>
                <div class='input-field col s12'>
                  <input class='validate' type='text' name='chat-name' id='chat-name' v-model.trim="name"/>
                  <label for="chat-name" class="grey-text text-darken-2">{{ $t('chat.attendee_name') }}
                    ({{ $t('chat.manager') }})</label>
                </div>

                <!-- input type="submit" :value="$t('chat.join')"
                       class="btn btn-large waves-effect orange lighten-3 grey-text text-darken-2"
                       style="min-width: 80%; margin-bottom: 1vh; border-radius: 3px;"
                /-->
            </form>
          </div>
        </div>
    </div>
    </section> <!--  END of Creator section  -->

  </div>
  <section> <!-- section for Attender -->

  </section>
  </div>
</template>
<script>
/* eslint-disable */
import {formBus} from "@/main";
import ChatCreator from './ChatCreator';
import {v4 as uuidV4} from 'uuid';


export default {
  name: "ChatForm",
  data() {
    return {
      room: this.$route.query.room || uuidV4(),          // Room ID
      name: '',                                          // Room Manager
      isChatForm: false,
      isCreator: true,
    }
  },
  components: {
    ChatCreator,
  },
  computed: {
    authorized() {
      return this.$store.getters['auth/authorized']
    },
    user_id() {
      return this.$store.getters['auth/user'].id
    },
  },
  methods: {
    join() {
      let role = 'client';
      // not authorized user could not be creator
      // also if room exist an authorized used is a client (not creator)
      // Check DB if room exists
      // if not
      // set authorized user role = creator
      // else set new users to client role
      if (this.authorized === true) {
        /* TODO: && room not in DB then role = creator */
        role = 'creator'
      }
      this.$store.dispatch('chat/setRoomName', this.room)
      this.$store.dispatch('chat/setMeName', this.name)
      this.$store.dispatch('chat/setMeRole', role)
      this.$store.dispatch('chat/setRoomActive', true)
      // insert DB Record(room-name, slug, creator, set active, 1. attendee)
      // DB: room-name = slug, if it is uuid, else slug is given room-name without empty char
      // set room in Database

      this.$router.push({
        name: 'room',
        params: {
          room: this.room
        }
      })
    },
    joinClient() {

    }
  },
  beforeMount() {
    formBus.$on('toggleChatForm', (isHeader) => {
      // console.log(isHeader);

      this.isChatForm = !isHeader
    })
    formBus.$on('hideChatForm', () => {
      this.isChatForm = false
    })
  },
  mounted() {
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  }
}
</script>

<style scoped lang="scss">
.chat-form {
  display: inline-block;
  width: 100%;
  min-height: var(--chatform-min-height);
  background: #D4D3B5;
  position: relative;
  padding-top: 20px;
  font: inherit;
  margin: 0 auto;
}

.form-width {
  max-width: 700px;
  margin: 0 auto;
}

.chat-participant {
  border-top: 1px solid #9e9e9e;
  min-height: 100px;
  padding: 20px;
  font: inherit;
}
</style>
