
export const getRoomName = (state) => state.room

export const getUser = (state) => state.user

export const getRoomActive = (state) => state.active

export const getMeUserName = (state) => state.user.name

export const getMeRole = (state) => state.user.role










export const getRoomUserMuted = (state) => state.user.muted

export const getRoomUserPaused = (state) => state.user.paused


// allRoomAttendees to use in FE Listing and to store in DB
export const getRoomAttendees = (state) => state.attendees
//
