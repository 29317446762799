// import Chat from '@/components/chat/Index.vue'
import Chat from '@/components/chat/ChatForm.vue'
import RoomIndex from '@/components/chat/room/RoomIndex'
// import store from '@/store'

export default [
    {
        path: 'chat',
        component: Chat,
        name: 'chat',
        meta: {
            guest: true,
            needsAuth: false
        }
    },
    {
        path: 'room/:room',
        component: RoomIndex,
        name: 'room',
        meta: {
            guest: false,
            needsAuth: true
        },
        props: true,  // ChatForm(join method) as props -> router that accept props -> RoomIndex, path: 'room/:roomId'
       //beforeEnter: (to, from, next) => {
       //    if (!this.$store.getters['chat/getUser'].name){
       //        next({
       //            name: 'room',
       //            query: {
       //                room: to.params.room
       //            }

       //        })
       //    }
       //    next()
       // }
    }
]
