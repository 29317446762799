import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import WebRTC from 'vue-webrtc'
import * as io from 'socket.io-client'
import i18n from './i18n'
import Notifications from 'vue-notification'
import './assets/scss/style.scss'
import 'materialize-css'
// import 'bootstrap'
// import VueResource from 'vue-resource'
window.io = io

/// Vue.use(VueResource)

Vue.use(Notifications)

// to communicate between Navigation and UploadForm
export const formBus = new Vue()

Vue.component('Navigation', require('./views/components/Navigation').default)
Vue.config.productionTip = false
Vue.use(WebRTC)
Vue.config.devtools = true
// var vue_jarrar = new Vue({
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
