
import Rooms from '@/components/rooms/Rooms'

export default [
    {
        path: 'rooms',
        component: Rooms,
        name: 'rooms',
        meta: {
            guest: false,
            needsAuth: true
        }
    },
]
