export const SET_ROOM_NAME = (state, room) => {
    state.room = room
}

export const SET_ROOM_ACTIVE = (state, active) => {
    state.active = active
}
export const SET_ME_USER_NAME = (state, name) => {
    state.user.name = name
}

export const SET_ME_USER_ROLE = (state, role) => {
    state.user.role = role
}


// for test only
export const SET_CUSTOMER_NUMBER = (uid) => {
    console.log('CHAT MUTATION TEST', uid)
}

/*
export const ALL_CHAT_ROOMS = (state, allRooms) => {
    state.all_rooms = allRooms
}

export const SET_USER_CHAT_DATA = (state, payload) => {
    state.username = payload.name
    state.meRole = payload.participantRole
    state.canActiveRooms = payload.canActiveRooms
}

export const SET_ME_STATUS = (state, status) => {
    state.status = status
}

export const SET_ME_USERNAME = (state, username) => {
    state.username = username
}

export const SET_ACTIVE_ROOM = (state, room) => {
    state.activeRoom = room
}

export const RESET_ACTIVE_ROOM = (state) => {
    state.activeRoom = null
}

export const SET_PAUSED = (state, value) => {
    state.user.paused = value
}
*/


// FE
export const ADD_ROOM_ATTENDEE = (state, attendee) => {
    alert('Mutation: ' + attendee)
    state.attendees.push(attendee)
}
export const UPDATE_ROOM_ATTENDEE = (state, attendee) => {
    let index = state.attendees.indexOf(attendee)
    if(index != -1) {
        state.attendees[index] = attendee
    }
}
export const DEL_ROOM_ATTENDEE = (state, attendee) => {
    let index = state.attendees.indexOf(attendee)
    this.state.attendees.splice(index, 1)

}
