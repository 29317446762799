export default {
    room: '',                      // room name
    isActive: false,               // true if room is activated
    user: {                        // me user object
        name: '',                  // name of user
        role: '',                  // role: creator, who can activate room
        paused: false,             // me set to paused video
        muted: false               // me set to muted video
    },
    users: [],                      // all clients incl. creator or activator
    // =======================================================================
    // attendees to be stored in DB table, without activator
    // =======================================================================
    attendees: [],  // attendees but activator
}

