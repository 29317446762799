<template>
  <div>
    <div class="row">
      <div class="col s12 m12">
        <div class="attendees-area">
          <h5>Add Attendees</h5>
          <div class="input-field col s9">
            <input type="text" id="attendee-name" v-model="attendee"/>
            <label for="attendee-name" class="grey-text text-darken-2">New Attendee (Name Surname)</label>
          </div>
          <div class="col s3">
            <input value="Add/ Edit Attendee" @click="submitAttendee"
                   class="btn btn-large waves-effect orange lighten-3 grey-text text-darken-2"
                   style="border-radius: 3px;"/>
          </div>
        </div>
      </div>
    </div>
    <h5>Attendees</h5>
    <br>
    <div class="row">
      <div class="col s5">Name Surname</div>
      <div class="col s2 offset-5">Status</div>
      <div class="col s2 offset-7">Edit</div>
      <div class="col s2 offset-9">Del</div>
    </div>
    <div class="row" v-for="(attendee, index) in attendees" :key="index">
      <div v-if="index > 0">
        <div class="col s5">{{ attendee.name }}</div>
        <div class="col s2 offset-5"><label><input type="checkbox" checked/><span></span></label></div>
        <div class="col s2 offset-7" @click="editAttendee(index)"><i class="material-icons pointer">edit</i></div>
        <div class="col s2 offset-9" @click="deleteAttendee(index)"><i class="material-icons pointer">delete</i></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatCreator",
  data() {
    return {
      isAttendee: false,
      attendee: '',
      editedAttendee: null,
      attendees: [
        {
          name: ''
        }
      ]
    }
  },

  methods: {
    submitAttendee() {
      let participant = this.attendee.trim()
      if (participant.length === 0) {
        return;
      }
      this.attendee = participant
      /* update attendee */
      if (this.editedAttendee != null) {
        this.attendees[this.editedAttendee].name = this.attendee;
        this.editedAttendee = null;
      } else {
        /* add attendee */
        this.attendees.push({
          name: this.attendee
        });
      }
      this.attendee = "";
    },
    editAttendee(index) {
      this.attendee = this.attendees[index].name;
      this.editedAttendee = index;
    },
    deleteAttendee(index) {
      this.attendees.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.attendees-area {
  max-width: 900px;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}
</style>
