<template>
  <div>
    <div class="row">
      <div class="col s7">
        <div v-if="!toEdit">{{ person }}</div>
        <!-- available to change if edit button clicked -->
        <div v-else>
          <input
              :value="attendeeText"
              @change="attendeeTextChange"
              type="text"
              class="col s12"
          />
        </div>
      </div>
      <div class="col s5">
        <div class="right">
          <!--button @click="updateAttendee()" id="ed-btn" class="btn-small waves-effect orange lighten-2 waves-light btn" style="margin-right: .1cm;"><i
              class="material-icons">{{toEdit ? 'update' : 'edit' }}</i> {{ toEdit ? 'update' : 'edit' }}</button-->
          <button v-if="!toEdit" @click="editAttendee()" id="edit-btn" class="btn-small waves-effect orange lighten-2 waves-light btn" style="margin-right: .1cm;"><i
              class="material-icons">edit</i>edit</button>
          <button v-if="toEdit" @click="updateAttendee()" id="update-btn" class="btn-small waves-effect orange lighten-2 waves-light btn" style="margin-right: .1cm;"><i
              class="material-icons">update</i>update</button>
          <button @click="deleteAttendee()" class="btn-small waves-effect red darken-4 waves-light btn">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Attendee",
  data() {
    return {
      toEdit: false, // toEdit: only for this attendee
      attendeeText: '',
      attendee: ''
    }
  },
  props: ['person'],
  mounted() {
    //
  },
  computed: {
    //
  },
  methods: {
    attendeeTextChange(e) {
      this.attendeeText = e.target.value;
    },
    editAttendee() {
      this.toEdit = true
      this.attendee = this.person
      this.attendeeText = this.attendee
    },
    updateAttendee() {
      this.toEdit = false
      this.attendee = this.attendeeText
      this.$emit('update-attendee-data', {'person': this.person, 'attendee': this.attendee})
      this.attendeeText = this.attendee
      alert('Update State')
    },
    deleteAttendee() {
      this.$emit('delete-listed-attendee', this.attendee)
    },
  }
}
</script>

<style scoped>

</style>
