<template>
  <div>
    <div class="create-room" v-if="isRooms">
      <section class="section" v-if="isCreator">
        <div class="row">
          <div class="col s12">
            <div class="center create-room-width">
              <h5>{{ $t('chat.chat_room_create') }}</h5>
              <div class='input-field col s12'>
                <input class="validate" type="text" name="create-room" id="create-room" v-model.trim="room" required />
                <label for="create-room" class="grey-text text-darken-2 active">{{ $t('chat.room_id') }}</label>
              </div>
              <!-- The Creator of Room (First One) -->
              <div class='input-field col s12'>
                <input class='validate' type='text' name='creator-name' id='creator-name' v-model.trim="activator" required />
                <label for="creator-name" class="grey-text text-darken-2">{{ $t('chat.attendee_name') }}
                  ({{ $t('chat.manager') }})</label>
              </div>
              <!-- Room Internal Name e.g. File Number -->
              <div class='input-field col s12'>
                <input class='validate' type='text' id='room-internal-name' v-model.trim="roomInternalName"/>
                <label for="room-internal-name" class="grey-text text-darken-2">{{
                    $t('chat.room_internal_name')
                  }}</label>
              </div>
            </div>
            <!-- the computed property "isRoomFull" observe the max. number of Attendees (without activator) -->
            <!-- Edit new Attendee, with (+) button add to list not to database -->
            <!-- Input field to Edit Attendee -->
            <div class="create-room-width">
              <div class="attendee-add" v-if="!isRoomFull">
                <h5 class="grey-text text-darken-4">Teilenehmer hinzufügen(max. 3)</h5>
                <div class='input-field col s10 left'>
                  <input @change="attendeeNameInput"
                         :value="attendee"
                         class='validate'
                         type='text'
                         name='attendee-name' id='attendee-name'/>
                  <label for="attendee-name" class="grey-text text-darken-2">{{ $t('chat.attendee_name') }}</label>
                </div>
                <!-- (+) Add Button, add attendee to list after editing -->
                <div class="col s2">
                  <button
                      class="btn-large waves-effect waves-light right orange lighten-2"
                      style="border-radius: 3px"
                      @click="addAttendee"
                      :title="$t('chat.attendee_add')"
                  ><i class="material-icons">add</i>
                  </button>
                </div>
              </div>
            </div>
            <!-- Attendees List -->
            <div class="create-room-width">
              <div class="left-align">
                <h5 class="grey-text text-darken-2">Teilenehmerliste(max. 3)</h5>
                <hr>
              </div>
              <div class="row">
                <div class="col s12">
                  <div class="attendee-list">
                    <div v-for="person in attendees" :key="person.index">
                      <!-- to edit or to delete attendee: every Attendee has an update button and a delete button -->
                      <Attendee :person="person" @update-attendee-data="updateListedAttendeeData" @delete-listed-attendee="deleteListedAttendee"></Attendee>
                    </div>
                  </div>
                </div>
                <div v-if="isCreateButton">
                  <div class="col s12">
                    <!-- make the room available = store the room in Database -->
                    <!-- Save whole Room in Database (room ID, Activator, room internal name, and attendee list)  -->
                    <button @click="storeRoom"
                            class="left btn-large waves-effect waves-light orange lighten-2"
                            :title="$t('chat.save_room_data')"><i class="large material-icons">chat</i>
                      {{ $t('chat.save') }}
                    </button>
                    <button @click="deleteAttendeesList"
                            class="right btn-large waves-effect waves-light orange lighten-2"
                            :title="$t('chat.ignore_room_data')"><i class="large material-icons">cancel</i>
                      {{ $t('chat.ignore') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <div class="center-align">
              <h5 class="grey-text text-darken-2">Available Rooms Listing</h5>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {formBus} from "@/main";
import {v4 as uuidV4} from "uuid";
import Attendee from "@/components/rooms/Attendee";
// import AttendeeList from "@/components/rooms/partials/AttendeeList";

export default {
  name: "Rooms",
  data() {
    return {
      room: this.$route.query.room || uuidV4(),          // Room ID
      roomInternalName: '',                              // e.g. Business number or File number
      activator: '',                                          // Room Manager (activator: who activate the room)
      isRooms: false,
      isCreator: true,
      attendees: [],
      attendee: '',
    }
  },
  components: {
    Attendee
    // AttendeeList,
  },
  mounted() {
    console.log('THE RComponent is LOADED');
  },
  computed: {
    isRoomFull() {
      return 3 === this.attendees.length
    },
    isCreateButton() {
      return this.attendees.length >= 1
    },
    uid() {
      return this.$store.getters['auth/user'].id
    }
  },
  methods: {
    attendeeNameInput(e) {
      this.attendee = e.target.value
    },
    addAttendee() {
      let attendeeName = this.attendee.trim()
      this.attendee = attendeeName
      if (this.attendee.length < 1) {
        return
      }
      // the name is ready exists
      if (this.attendees.includes(this.attendee)){
        return
      }
      this.attendees.push(this.attendee)
      // console.log(this.attendee)
      // console.log(this.attendees)
      this.attendee = ''
    },
    updateListedAttendeeData(data) {
      console.log('Before Update: ' + this.attendees)
      let index =  this.attendees.indexOf(data.person)
      this.attendees[index] = data.attendee
      console.log('After Update' + this.attendees)
      // this.attendee = person
    },
    deleteListedAttendee(attendee) {
      let ind = this.attendees.indexOf(attendee)
      this.attendees.splice(ind, 1)
    },
    deleteAttendeesList() {
      this.attendee = ''
      this.attendees = []
    },
    storeRoom() {
      let attendees = []
      let attendee = ''
      const persons = this.attendees
      const len = persons.length
      for (let i=0; i < len; i++){
        let attendee = {
          "name": persons[i],
          "status": "0"
        }

        attendees.push(attendee)
      }
      const payload = {
        'user_id': this.uid,
        'room_id': this.room,
        'room_internal_name': this.roomInternalName,
        'activator': this.activator,
        'attendees': attendees
      }
      console.log('Room Payload');
      console.log(payload)
      this.$store.dispatch('rooms/saveRoomData', payload).then(()=>{
        M.toast({html: this.$i18n.t('chat.room_added'), classes: 'rounded orange-text'})
      })
    },
  },
  beforeMount() {
    formBus.$on('toggleRoomsForm', (isHeader) => {
      // console.log(isHeader);

      this.isRooms = !isHeader
      // alert('ROOMS PREPARE 1: ' + this.isRooms)
    })
    formBus.$on('hideRoomsForm', () => {
      this.isRooms = false
    })
  },
}
</script>

<style scoped lang="scss">
.create-room {
  display: inline-block;
  width: 100%;
  min-height: var(--chatform-min-height);
  background: #D4D3B5;
  position: relative;
  padding-top: 20px;
  font: inherit;
  margin: 0 auto;
}

.create-room-width, .attendee-create {
  max-width: 700px;
  margin: 0 auto;
}

.create-button {
  margin-top: 20px;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

</style>
