<template>
  <!--p class="room">Room: {{ room }}</p-->
  <div class="room">
    <div class="people" id="people">
      <div class="person" v-for="(x, index) in 4" :key="index">
        <div class="person__video">
          <video></video>
        </div>
        <div class="center person_name">
          Ibrahim
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomIndex",
  props: {
    room: String  // passed via router from chatForm(room, attendee)
  },
  mounted () {
    // alert('In RoomIndex Component: ' + this.room)
  }
}
</script>

<style scoped lang="scss">
.room {
  height: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: var(--chatform-min-height);
  background: #D4D3B5;
  padding-top: 20px;
  font: inherit;
  margin: 0 auto;
}

.people {
  display: flex;
  flex-wrap: wrap;
  vertical-align: baseline;
  width: 100%;
  padding: 40px;
}

video {
  width: 100%;
  /* background-color: #000; */
}

.person {
  position: relative;
  margin: 0 1% 60px 1%;
  width: 100%;

  &__video {
    background-color: #ccc;
    margin-bottom: 10px;
    box-shadow: 0 10px 25px rgba(49, 49, 49, .2);
    border-radius: 3px;
  }

  &__name {
    text-align: center;
    font-weight: 600;
  }
  /* @media (min-width: 576px) and (max-width: 767px) { */
  @media (min-width: 576px) and (max-width: 991px) {
    width: 49%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(2n+1) {
      margin-left: 0;
    }
  }
/*
  @media (min-width: 768px) and (max-width: 991px) {
    width: 32%;
    &:nth-of-type(3n) {
      margin-right: 0;
      border-right: 1px solid #ff0000;
    }
    &:nth-of-type(3n+1) {
      margin-left: 0;
      border-right: 1px solid #ff0000;
    }
  }
*/
  @media (min-width: 992px) {
    width: 23.5%;
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(4n+1) {
    margin-left: 0;
  }
}
</style>

